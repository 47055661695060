import React, { useEffect } from "react";
import { useState } from "react";
import Heading from "./Heading";
import axios from "axios";
import { IoMdLink } from "react-icons/io";
import { HiOutlineCalendar } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { baseApiURL } from "../baseUrl";

const ViewNotices = () => {
    const router = useLocation();
    const [notice, setNotice] = useState([]);
    const [open, setOpen] = useState(false);
    const [taskStates, setTaskStates] = useState([]);

    useEffect(() => {
        getNoticeHandler();
    }, [router.pathname]);

    const getNoticeHandler = () => {
        let data = {};
        if (router.pathname.replace("/", "") === "daily") {
            data = {
                type: ["daily", "monthly"],
            };
        } else {
            data = {
                type: ["daily", "monthly", "weekly", "one-time"],
            };
        }
        const headers = {
            "Content-Type": "application/json",
        };
        axios
            .post(`${baseApiURL()}/notice/getNotice`, data, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.success) {
                    setNotice(response.data.notice);
                    setTaskStates(response.data.notice.map(item => ({
                        wallet1: item.wallet1,
                        wallet2: item.wallet2,
                        wallet3: item.wallet3,
                        wallet4: item.wallet4,
                        wallet5: item.wallet5,
                        wallet6: item.wallet6,
                        wallet7: item.wallet7,
                    })));
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.dismiss();
                toast.error(error.response.data.message);
            });
    };

    const handleCheckboxChange = (taskIndex, wallet) => {
        const updatedTaskStates = [...taskStates];
        updatedTaskStates[taskIndex][wallet] = !updatedTaskStates[taskIndex][wallet];
        setTaskStates(updatedTaskStates);
    };

    const updateDatabase = (taskIndex) => {
        const updatedWallets = taskStates[taskIndex];
        const taskId = notice[taskIndex]._id;
        axios
            .post(`${baseApiURL()}/notice/updateNotice/${taskId}`, updatedWallets) 
            .then((response) => {
                if (response.data.success) {
                    toast.success("Wallets updated successfully!");
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };
    
    return (
      <div className="w-[95%] mx-auto flex justify-center items-start flex-col my-10">
        <Heading title="Tasks" />
        {!open && (
          <div className="mt-8 w-full">
            {notice.map((item, index) => (
              <div
                key={item._id}
                className="border-blue-500 border-2 w-full rounded-md shadow-sm py-4 px-6 mb-4 relative"
              >
                <p
                  className={`text-2xl font-medium flex justify-start items-center ${
                    item.link && "cursor-pointer"
                  } group`}
                  onClick={() => item.link && window.open(item.link)}
                >
                  {item.title}
                  {item.link && (
                    <span className="text-3xl group-hover:text-blue-500 ml-1">
                      <IoMdLink />
                    </span>
                  )}
                </p>
                <p className="text-lg font-normal mt-1 break-all">{item.description}</p>
                <div className="flex flex-col md:flex-row md:items-center">
                  <div className="flex flex-wrap items-center">
                    {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                      <label key={num} className="flex items-center mr-4 p-2">
                        <input
                          type="checkbox"
                          className="mr-2 h-5 w-5"
                          checked={taskStates[index][`wallet${num}`]}
                          onChange={() => handleCheckboxChange(index, `wallet${num}`)}
                        />
                        Module {num}
                      </label>
                    ))}
                  </div>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md ml-0 mt-4 md:ml-4 md:mt-0"
                    onClick={() => updateDatabase(index)}
                  >
                    Update
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
    
};

export default ViewNotices;